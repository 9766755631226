iframe #mic{
  visibility: hidden !important;
}

iframe .b-agent-demo .b-agent-demo_input-microphone{
  visibility: hidden !important;
  display: none!important;
}
body::-webkit-scrollbar {
  width: 10px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(4, 27, 78);
}
 
body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgb(75, 128, 243);
  outline: 1px solid rgb(52, 94, 136);
}